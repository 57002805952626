import React from 'react'

import { SectionIds } from '../../components/platform-common-components'
import OperationsPage from './operations'

const NoiseSensor = (props) => {
  return (<OperationsPage {...props} scrollTo={SectionIds.noiseSensor} />)
}

export default NoiseSensor
